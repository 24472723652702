import React from 'react';

// Layout
import Layout from '../containers/layout/Layout';

// Components
import Banner from '../components/common/banner/Banner';
import TextWidget from '../components/common/text-widget/TextWidget';
import DeliverServices from '../components/common/services/DeliverServices';

// Image
import bannerImage from '../../static/assets/banner/banner-services.png';

// Data
import {useServicesText} from '../graphql/services/servicesText';
import {useServices} from '../graphql/services';

const Services = () => {
  const serviceTextData = useServicesText();
  const servicesData = useServices();

  const bannerOpacity = {
    opacity: '0.75',
  };

  const bannerOverlay = {
    backgroundImage: `url(${bannerImage})`,
  };

  return (
    <Layout>
      <Banner
        bannerOpacity={bannerOpacity}
        bannerOverlay={bannerOverlay}
        text="We transform your business with customised software solutions"
        className="bannerSecondary"
      >
        Services
      </Banner>
      <TextWidget data={serviceTextData} className="text-widget--services" />
      <DeliverServices
        data={servicesData}
        servicesTitle="We are technologists at heart."
        servicesSubtitle="Read about our areas of expertise below:"
        className="services--servServices"
      />
    </Layout>
  );
};

export default Services;
